import {GetCategoryInitialDataQuery} from '../graphql/queries-schema';
import {FilterConfigType, IFilterConfigDTO} from '../types/galleryTypes';
import {DEFAULT_COLLECTION_ID} from '../constants';
import {RouterPrefix} from '@wix/wixstores-client-core';

export const initCategoryList = ({
  initialData,
  shouldUseCategoryListConfig,
  baseUrl,
}: {
  initialData: GetCategoryInitialDataQuery;
  shouldUseCategoryListConfig: boolean;
  baseUrl: string;
}) => {
  let categories = initialData.catalog.categories.list;
  const categoryListConfig = initialData.appSettings.widgetSettings.CATEGORY_LIST_CONFIG;
  const categoryFilter = categoryListConfig?.find(
    (f) => f.filterType === FilterConfigType.CATEGORY
  ) as IFilterConfigDTO;
  if (shouldUseCategoryListConfig && categoryFilter?.selected.length) {
    const sortedIds = [];
    const selectedCategoriesFromConfig = categoryFilter.selected.reduce((obj, item) => {
      obj[item.id] = null;
      sortedIds.push(item.id);
      return obj;
    }, {});

    categories = categories
      .filter((c) => c.visible && c.id in selectedCategoriesFromConfig)
      .sort((a, b) => sortedIds.indexOf(a.id) - sortedIds.indexOf(b.id));
  } else {
    categories = categories.filter((c) => c.visible).sort((a, b) => a.name.localeCompare(b.name));
    const defaultCategoryIndex = categories.findIndex((c) => c.id === DEFAULT_COLLECTION_ID);
    if (defaultCategoryIndex !== -1) {
      categories.unshift(categories.splice(defaultCategoryIndex, 1)[0]);
    }
  }

  return categories.map((c) => {
    return {
      ...c,
      categoryUrl: `${baseUrl}/${RouterPrefix.CATEGORY}/${c.slug}`,
    };
  });
};
